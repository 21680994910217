import { sanitizeUrl } from "@braintree/sanitize-url";
import { signIn, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { constants } from "~/common/constants";
import { useAnalytics } from "~/components/Analytics";
import { AuthenticationLayout } from "~/components/AuthenticationLayout/AuthenticationLayout";
import { PageMeta } from "~/components/PageMeta";
import { useRegistrationReminderBanner } from "~/components/PersistentState";
import { commonConfig } from "~/config/common-config";
import { wrapGetStaticProps } from "~/lib/server/wrapGetProps";
import { NextPageWithLayout } from "~/types/endil/NextPageWithLayout";

const filterRedirectParam = (
  redirect: string,
  defaultRedirect = "/"
): string => {
  const sanitized = sanitizeUrl(redirect);
  if (sanitized === "about:blank") {
    return defaultRedirect;
  }
  return sanitized.replace(/^\/?/, "/");
};

export const getStaticProps = wrapGetStaticProps(async () => ({
  props: { meta: { piano: { type: "simple" } } },
}));
const LoginPage: NextPageWithLayout<{}> = () => {
  const router = useRouter();
  const { data: sessionData, status } = useSession();
  const { analyticsUserIdentified } = useAnalytics();
  const { setRegistrationReminderEmail } = useRegistrationReminderBanner();

  const loginSessionCount = sessionData?.user.loginSessionCount;

  useEffect(() => {
    if (status === "unauthenticated") {
      signIn(commonConfig.NEXT_PUBLIC_ENDIL_IDENTITY_PROVIDER);
    }
  }, [status]);

  useEffect(() => {
    const afterLogin = async () => {
      if (status !== "authenticated" || analyticsUserIdentified !== true) {
        return;
      }

      const localStorageRedirect = window.localStorage.getItem(
        constants.REGISTRATION_REDIRECT_STORAGE_KEY
      );

      // Triggers the removals of the RegistrationReminderBanner from the UI
      setRegistrationReminderEmail(null);

      // Always remove the redirect key
      window.localStorage.removeItem(
        constants.REGISTRATION_REDIRECT_STORAGE_KEY
      );

      /*
        Scenario 1: `source=...` in the query param indicates
        that this was launched as a child window
        
        Scenario 2: `redirect=...` in the query param initiates
        a path for in-app navigation on the current window

        Scenario 3: window.localStorage holds a value in the REGISTRATION_REDIRECT_STORAGE_KEY
        key, which will initiate an in-app navigation in the current window

        Scenario 4: No query params, initiate in-app navigation to the homepage `/` 
      */
      if ("source" in router.query) {
        // Add slight delay for any in-flight network requests to resolve.
        // Note: processLogin is awaited above and guaranteed to finish before arriving here.
        setTimeout(() => {
          window.close();
        }, 200);
        return;
      }

      if (typeof router.query.redirect === "string") {
        router.replace(filterRedirectParam(router.query.redirect));
        return;
      }

      if (localStorageRedirect) {
        router.replace(filterRedirectParam(localStorageRedirect));
        return;
      }

      router.replace("/");
    };
    afterLogin();
  }, [
    analyticsUserIdentified,
    loginSessionCount,
    router,
    setRegistrationReminderEmail,
    status,
  ]);

  return (
    <>
      <PageMeta
        seo={{
          title: "Login",
        }}
      />
    </>
  );
};

LoginPage.getLayout = (page) => (
  <AuthenticationLayout loadingText="Signing in..." page={page} />
);

export default LoginPage;
